export function applyMethodQueue(queue, context) {
    if (!context || !queue || !queue.length) {
        return;
    }
    for (var _i = 0, queue_1 = queue; _i < queue_1.length; _i++) {
        var _a = queue_1[_i], method = _a[0], args = _a[1];
        if (method) {
            context[method].apply(context, args);
        }
    }
}
// https://www.w3docs.com/snippets/javascript/how-to-detect-internet-explorer-in-javascript.html
export function getIeVersion() {
    var detectIEregexp;
    // eslint-disable-next-line @typescript-eslint/prefer-includes
    if (navigator.userAgent.indexOf('MSIE') !== -1) {
        // test for MSIE x.x
        detectIEregexp = /MSIE (\d+\.\d+);/;
    }
    else {
        // if no "MSIE" string in userAgent
        // test for rv:x.x or rv x.x where Trident string exists
        detectIEregexp = /Trident.*rv[ :]*(\d+\.\d+)/;
    }
    // if some form of IE
    var test = detectIEregexp.test(navigator.userAgent);
    if (test) {
        // capture x.x portion and store as a number
        var match = navigator.userAgent.match(detectIEregexp);
        if (match && match.length === 2) {
            var version = new Number(RegExp.$1).valueOf();
            if (Number.isFinite(version)) {
                return version;
            }
        }
    }
    return undefined;
}

import { isNil } from 'utils';
var Cache = /** @class */ (function () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function Cache() {
        var props = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            props[_i] = arguments[_i];
        }
        this.implMap = new Map(props);
    }
    // we never overwrite a non-nil value with a nil one
    // (i.e., use delete to unset)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cache.prototype.set = function (key, value) {
        if (!isNil(value)) {
            return this.implMap.set(key, value);
        }
        return this;
    };
    // kind of a cross between Object.assign and _.pick()
    //  - only operates on the keys listed (leaves other props untouched)
    //  - caches non-nil values
    //  - applies cached props
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cache.prototype.assign = function (target) {
        var keys = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            keys[_i - 1] = arguments[_i];
        }
        for (var _a = 0, keys_1 = keys; _a < keys_1.length; _a++) {
            var key = keys_1[_a];
            this.set(key, target[key]);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            target[key] = this.get(key);
        }
        return target;
    };
    // extending / implementing Map is hard
    Cache.prototype.has = function (key) {
        return this.implMap.has(key);
    };
    Cache.prototype.get = function (key) {
        return this.implMap.get(key);
    };
    Cache.prototype.delete = function (key) {
        return this.implMap.delete(key);
    };
    Cache.prototype.clear = function () {
        return this.implMap.clear();
    };
    Object.defineProperty(Cache.prototype, "size", {
        get: function () {
            return this.implMap.size;
        },
        enumerable: false,
        configurable: true
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cache.prototype.forEach = function (callback, ctx) {
        return this.implMap.forEach(callback, ctx);
    };
    return Cache;
}());
export default Cache;

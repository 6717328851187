var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import fetch from 'unfetch';
import assignDeep from 'assign-deep';
// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-assignment
var debug = require('debug')('Vitally.js:Fetchable');
// only supports json encoding for now
var GLOBAL_DEFAULTS = {
    headers: {
        'Content-Type': 'application/json',
    },
};
var JsonFetchable = /** @class */ (function () {
    function JsonFetchable(url, props) {
        this.url = url;
        this.getDefaults = props !== null && props !== void 0 ? props : (function () { return ({}); });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    JsonFetchable.prototype.get = function (resource, props) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.callFetch('GET', resource, props)];
            });
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    JsonFetchable.prototype.post = function (resource, props) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.callFetch('POST', resource, props)];
            });
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    JsonFetchable.prototype.put = function (resource, props) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.callFetch('PUT', resource, props)];
            });
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    JsonFetchable.prototype.delete = function (resource, props) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.callFetch('DELETE', resource, props)];
            });
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    JsonFetchable.prototype.options = function (resource, props) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.callFetch('OPTIONS', resource, props)];
            });
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    JsonFetchable.prototype.callFetch = function (method, resource, callProps) {
        return __awaiter(this, void 0, void 0, function () {
            var fullPath, defaultProps, fullProps, objBody, response, body, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fullPath = this.url + resource;
                        // for convenience, infer body if we don't see properties we know about
                        // ...will break if it has properties by these names in their body
                        if (callProps) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            if (!callProps.method && !callProps.body && !callProps.headers) {
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                callProps = { body: callProps };
                            }
                        }
                        defaultProps = this.getDefaults();
                        fullProps = assignDeep({}, GLOBAL_DEFAULTS, defaultProps, callProps, { method: method });
                        objBody = fullProps.body;
                        Object.assign(fullProps, {
                            // make sure to stringify the body before we actually send
                            body: JSON.stringify(objBody),
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        debug("".concat(method, " ").concat(fullPath, " STARTED"), objBody);
                        return [4 /*yield*/, fetch(fullPath, fullProps).then(checkStatus)];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3:
                        body = _a.sent();
                        return [2 /*return*/, body];
                    case 4:
                        err_1 = _a.sent();
                        debug("".concat(method, " ").concat(fullPath, " FAILED"));
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return JsonFetchable;
}());
export default JsonFetchable;
// https://github.com/developit/unfetch#caveats
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function checkStatus(response) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (response.ok) {
        return response;
    }
    else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        var error = new Error(response.statusText);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        error.response = response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Promise.reject(error);
    }
}
